import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Coverage, COVERAGE_TYPE } from '@root/types';
import React from 'react';
import styled from 'styled-components';

interface OwnProps {
  onChange(coverage: Coverage): void;
}

const CoverageForm = (props: OwnProps) => {
  const [coverage, setCoverage] = React.useState({
    type: COVERAGE_TYPE.ALL,
    distance: 0,
    countryName: '',
  });
  const [coverageType, setCoverageType] = React.useState(COVERAGE_TYPE.ALL);
  const [distance, setDistance] = React.useState(0.0);
  const [country, setCountry] = React.useState('');

  const onCoverageSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCoverageType(COVERAGE_TYPE[event.target.value as string]);
    const newValue = {
      type: COVERAGE_TYPE[event.target.value as string],
      distance: 0,
      countryName: '',
    };
    setCoverage(newValue);
    props.onChange(newValue);
  };

  const onDistanceChange = (event) => {
    const dist = event.target.value as number;
    setDistance(dist);
    const newValue = {
      ...coverage,
      distance: dist,
    };
    setCoverage(newValue);
    props.onChange(newValue);
  };

  const onCountryChange = (event) => {
    const country = event.target.value;
    setCountry(country);
    const newValue = {
      ...coverage,
      countryName: country,
    };
    setCoverage(newValue);
    props.onChange(newValue);
  };

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <h4>Coverage:</h4>
      <FormControl>
        <InputLabel id="select-coverage">Type</InputLabel>
        <Select labelId="coverage-select-label" id="coverage-select" value={coverageType} onChange={onCoverageSelect}>
          <MenuItem value={COVERAGE_TYPE.ALL}>All</MenuItem>
          <MenuItem value={COVERAGE_TYPE.KM}>Km</MenuItem>
          <MenuItem value={COVERAGE_TYPE.COUNTRY}>Country</MenuItem>
        </Select>
      </FormControl>
      {coverageType === COVERAGE_TYPE.KM && (
        <TextField
          fullWidth
          id="distance"
          name="distance"
          label="Distance"
          onChange={onDistanceChange}
          value={distance}
        />
      )}
      {coverageType === COVERAGE_TYPE.COUNTRY && (
        <TextField
          fullWidth
          id="countryName"
          name="countryName"
          label="Country name"
          onChange={onCountryChange}
          value={country}
        />
      )}
    </div>
  );
};

const FormWrapper = styled.div`
  flex-direction: column;
  padding-horizontal: 20;
`;

export default CoverageForm;
