import { LoadingView, useRootStore } from '@root/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import EventCard from './components/event-card';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {Button, FormControl, Grid,  InputLabel, MenuItem, Select} from '@material-ui/core';
import LoginPage from '@root/pages/login/login.page';

import AddIcon from "@material-ui/icons/Add";


enum ACTIONS {
  EDIT = 'edit',
  DELETE = 'delete',
}

const EventsPage = observer(() => {

  const { eventsStore, profileStore } = useRootStore();
  const onActionSelect = (event: React.ChangeEvent<{ value: unknown }>, eventId: string) => {
    const type = event.target.value as string;
    if (type === ACTIONS.DELETE) {
      eventsStore.delete(eventId);
    }
  };

  useEffect(() => {
    eventsStore.fetchEvents();
  }, []);

  if (eventsStore.isLoading) {
    return <LoadingView title="Events are loading..." />;
  }

  if (!localStorage.getItem('authToken')) {
    return <LoginPage />;
  }

  if (eventsStore.events.length === 0) {
    return <h1>No events found.</h1>;
  }

  return (
    <>
      <Link component={RouterLink}
            to={`/events/create`}>
        <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon/>}
        >
          Add Event
        </Button>
      </Link>

      {eventsStore.events.map((event) => (
        <>
          <Grid container spacing={3}>
            <Grid item xs={11}>
              <Link
                underline="none"
                component={RouterLink}
                to={{ pathname: `/events/${event.id}`, query: { id: event.id } }}
              >
                <EventCard key={event.id} event={event} />
              </Link>
            </Grid>
            <Grid item xs={1}>
              <FormControl style={{ display: 'flex' }}>
                <InputLabel id="event-action-select-label">Actions</InputLabel>
                <Select
                  labelId="event-action-select-label"
                  id="event-action-select"
                  value={''}
                  onChange={(e) => onActionSelect(e, event.id)}
                >
                  <Link
                    underline="none"
                    component={RouterLink}
                    to={{
                      pathname: `events/${event.id}/edit`,
                      query: { id: event.id },
                    }}
                  >
                    <MenuItem value={ACTIONS.EDIT}>Edit</MenuItem>
                  </Link>
                  <MenuItem value={ACTIONS.DELETE}>Delete</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      ))}
    </>
  );
});

export default EventsPage;
