import React, {useEffect, useState} from 'react';
import {Select, MenuItem, Grid} from '@material-ui/core';

interface Category {
    id: number;
    category_id: number;
    title: string;
    description: string;
    localizations_key: string;
    icon: string | null;
    subCategories?: Category[] | null;
}

interface DefaultCategories {
    mainCategory: Category | null;
    subcategory: Category | null;
    category: Category | null;
}

interface CategorySelectorProps {
    categories: any[];
    onCategorySelect: (category: any) => void;
    defaultCategories: DefaultCategories | null;
}
    const CategorySelector: React.FC<CategorySelectorProps> = ({categories, onCategorySelect, defaultCategories}) => {
    const [selectedMainCategory, setSelectedMainCategory] = useState<Category | null>(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState<Category | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);

    const findCategoryById = (categories: Category[] | null | undefined, categoryId: number | undefined): Category | null => {
        return categories?.find(cat => cat.id === categoryId) || null;
    };
    useEffect(() => {
        if (!defaultCategories || !defaultCategories.mainCategory) {
            return;
        }
        const defaultMainCategory = findCategoryById(categories, defaultCategories.mainCategory?.category_id);
        setSelectedMainCategory(defaultMainCategory);
        const defaultSubCategory = findCategoryById(defaultMainCategory?.subCategories, defaultCategories.subcategory?.category_id);
        setSelectedSubCategory(defaultSubCategory);
        const defaultCategory = findCategoryById(defaultSubCategory?.subCategories, defaultCategories.category?.category_id);
        setSelectedCategory(defaultCategory);
    }, [defaultCategories, categories]);


    const handleMainCategoryChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const categoryId = event.target.value as number;
        const category = findCategoryById(categories, categoryId);
        setSelectedMainCategory(category);

        if (category?.subCategories?.length) {
            const firstSubCategory = category.subCategories[0];
            setSelectedSubCategory(firstSubCategory);
            const firstCategory = firstSubCategory.subCategories?.length ? firstSubCategory.subCategories[0] : null;
            setSelectedCategory(firstCategory);          
            onCategorySelect(firstCategory || firstSubCategory || category);
        } else {
            onCategorySelect(category);
        }
    };

    const handleSubCategoryChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const categoryId = event.target.value as number;
        const category = findCategoryById(selectedMainCategory?.subCategories, categoryId);
        setSelectedSubCategory(category);
        if (category?.subCategories?.length) {
            const firstCategory = category.subCategories[0];
            setSelectedCategory(firstCategory);
            onCategorySelect(firstCategory || category);
        } else {
            onCategorySelect(category);
        }
    };

    const handleCategoryChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const categoryId = event.target.value as number;
        const category = findCategoryById(selectedSubCategory?.subCategories, categoryId);
        setSelectedCategory(category);
        onCategorySelect(category);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Select fullWidth value={selectedMainCategory ? selectedMainCategory.id : ""}
                        onChange={handleMainCategoryChange}>
                    {categories.map(category => (
                        <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                    ))}
                </Select>
            </Grid>
            {selectedMainCategory && selectedMainCategory.subCategories && selectedMainCategory.subCategories.length > 0 && (
                <Grid item xs={4}>
                    <Select fullWidth key={selectedMainCategory.id}
                            value={selectedSubCategory ? selectedSubCategory.id : ""}
                            onChange={handleSubCategoryChange}>
                        {selectedMainCategory.subCategories.map(category => (
                            <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            )}
            {selectedSubCategory && selectedSubCategory.subCategories && selectedSubCategory.subCategories.length > 0 && (
                <Grid item xs={4}>
                    <Select fullWidth key={selectedSubCategory.id} value={selectedCategory ? selectedCategory.id : ""}
                            onChange={handleCategoryChange}>
                        {selectedSubCategory.subCategories.map(category => (
                            <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            )}
        </Grid>
    );
}

export default CategorySelector;
