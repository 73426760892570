import ImageUploadView from "@root/components/image-upload/imageUpload.view";
import React, {useEffect, useState} from "react";
import {EventTypeEnum} from "@root/models/EventTypeEnum";
import {EventVisibilityEnum} from "@root/models/EventVisibilityEnum";
import {Box, Grid, TextField, MenuItem} from "@material-ui/core";
import {useRootStore} from '@root/components';

import CategorySelector from "@root/pages/events/components/category-selector";

interface Values {
    details: { locale: string, title: string, description: string }[],
    start: string | undefined;
    end: string | undefined;
    address: { address: string } | undefined;
}

interface EventFormFieldsProps {
    event: any;
    onEventChange: (event: any) => void;
    eventType: EventTypeEnum;
    defaultCategories: any;
    errors: Partial<Values>;
}

const EventFormFields: React.FC<EventFormFieldsProps> = ({
                                                             event,
                                                             onEventChange,
                                                             eventType,
                                                             defaultCategories,
                                                             errors
                                                         }) => {
    const {categoriesStore} = useRootStore();
    const [localEvent, setLocalEvent] = React.useState(event);
    const {eventsStore, fbEventsStore} = useRootStore();

    const [isLoading, setIsLoading] = useState(true);
    React.useEffect(() => {

        setLocalEvent(event);
    }, [event]);
    const [locales, setLocales] = useState(['pl']);

    const addEnglishLocale = () => {
        setLocales(prevLocales => {

            if (prevLocales.includes('en')) {
                return prevLocales;
            }
            return [...prevLocales, 'en'];
        });
    };

    useEffect(() => {
        categoriesStore.initialyFetchCategories().finally(() => setIsLoading(false));
    }, []);

    const onPicture = (file: File) => {
        if (eventType === EventTypeEnum.Event) {
            eventsStore.setPicture(file)
        } else if (eventType === EventTypeEnum.FacebookEvent) {
            fbEventsStore.setPicture(file)
        }
    };
    const handleDetailsChange = (index: number, field: string, value: string, locale: string) => {
        const newDetails = [...localEvent.details];
        let detailIndex = newDetails.findIndex(detail => detail.locale === locale);
        if (detailIndex === -1) {
            newDetails.push({locale, [field]: value});
        } else {
            newDetails[detailIndex][field] = value;
            if (!newDetails[detailIndex].title && !newDetails[detailIndex].description) {
                newDetails.splice(detailIndex, 1);
            }
        }
        const updatedEvent = {...localEvent, details: newDetails};
        setLocalEvent(updatedEvent);
        onEventChange(updatedEvent);
    };
    const handleEventFieldChange = (field: string, value: any) => {
        const updatedEvent = {...localEvent, [field]: value};
        setLocalEvent(updatedEvent);
        onEventChange(updatedEvent);
        console.log(updatedEvent)
    };

    if (isLoading) {
        return <h1>Loading...</h1>
    }

    if (categoriesStore.categories.length === 0) {
        return <h1>No categories found.</h1>
    }

    return (
        <>
            <h1>
                {eventType === EventTypeEnum.FacebookEvent ? 'Creating event from Facebook event' :
                    eventType === EventTypeEnum.Event ? 'Editing event' :
                        'Creating new event'}
            </h1>
            {['pl', 'en'].map((locale, index) => {
                let detail = localEvent.details.find(detail => detail.locale === locale);
                if (!detail) {
                    detail = {
                        locale,
                        title: "",
                        description: ""
                    };
                }

                return (
                    <Box key={index} marginBottom={2}>
                        <p>
                            Locale {detail.locale}:
                        </p>


                        <TextField
                            label="Title"
                            value={detail.title}
                            onChange={(e) => handleDetailsChange(index, 'title', e.target.value, locale)}
                            error={Boolean(errors.details?.[index]?.title)}
                            helperText={errors.details?.[index]?.title}
                            fullWidth
                        />
                        <TextField
                            label="Description"
                            value={detail.description}
                            onChange={(e) => handleDetailsChange(index, 'description', e.target.value, locale)}
                            error={Boolean(errors.details?.[index]?.description)}
                            helperText={errors.details?.[index]?.description}
                            fullWidth
                            multiline
                            minRows={4}
                        />
                    </Box>
                );
            })}
            <h3>Select category</h3>
            <CategorySelector
                categories={categoriesStore.categories}
                onCategorySelect={(category) => handleEventFieldChange('categoryId', category.id)}
                defaultCategories={defaultCategories}
            />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box marginBottom={2}>
                        <TextField
                            label="Max Participants Count"
                            type="number"
                            value={localEvent.maxParticipantsCount}
                            onChange={(e) => handleEventFieldChange('maxParticipantsCount', Number(e.target.value))}
                            fullWidth
                            inputProps={{min: 0}}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box marginBottom={2}>
                        <TextField
                            label="Visibility"
                            select
                            value={localEvent.type}
                            onChange={(e) => handleEventFieldChange('type', Number(e.target.value))}
                            fullWidth
                        >
                            <MenuItem value={EventVisibilityEnum.PRIVATE}>Private</MenuItem>
                            <MenuItem value={EventVisibilityEnum.FOR_FRIENDS}>For friends</MenuItem>
                            <MenuItem value={EventVisibilityEnum.PUBLIC}>Public</MenuItem>
                        </TextField>
                    </Box>
                </Grid>
            </Grid>
            <Box marginBottom={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Start"
                            type="datetime-local"
                            value={localEvent.start}
                            onChange={(e) => handleEventFieldChange('start', e.target.value)}
                            error={Boolean(errors.start)}
                            helperText={errors.start}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="End"
                            type="datetime-local"
                            value={localEvent.end}
                            onChange={(e) => handleEventFieldChange('end', e.target.value)}
                            error={Boolean(errors.end)}
                            helperText={errors.end}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box marginBottom={2}>
                <TextField
                    label="Address"
                    value={localEvent.address.address}
                    onChange={(e) => handleEventFieldChange('address', {address: e.target.value})}
                    error={Boolean(errors.address?.address)}
                    fullWidth
                />
            </Box>
            <ImageUploadView buttonText="Change picture" onImage={(file) => onPicture(file)}/>
        </>
    );
}

export default EventFormFields;