import type { AxiosResponse } from 'axios';
import { JtResponse } from '@root/types/api/api.types';

// NB: this is an example of how to build app interfaces
// [NAME]ApiResponse should be used for data you get from your api call
// [NAME] your entity
// if back-end [NAME] isn't convenient for your UI layer consider re-mapping it on Data layer (your data stores, e.g. JobsStore)

export type UsersApiResponse = AxiosResponse<JtResponse<User[]>>;

export interface User {
  avatar: string;
  birthday: string;
  gender: string;
  id: string;
  nickName: string;
}
