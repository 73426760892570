import { makeAutoObservable } from 'mobx';
import { CategoriesStore, RootStore } from '@root/stores';
import { CategoryDetails, CategoryDetailsData, EditCategory, LocalizedDetails } from '@root/types';

export class CategoryDetailsStore {
  isLoading = false;
  message = '';
  error = '';
  parentCategory?: CategoryDetailsData;
  categoryDetails?: CategoryDetailsData;
  language = 'en';
  redirectTocategoriesList = false;

  private root: RootStore;
  private categoriesStore: CategoriesStore;

  icon?: File;
  picture?: File;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    this.categoriesStore = rootStore.categoriesStore;
    makeAutoObservable(this);
  }

  initState() {
    this.redirectTocategoriesList = false;
  }

  canEditIcon() {
    return this.categoryDetails?.categoryDetails?.iconUploadAvailable || false;
  }

  canEditPhoto() {
    return this.categoryDetails?.categoryDetails?.pictureUploadAvailable || false;
  }

  async fetchParentCategoryDetails(parentCategoryId: string | undefined) {
    this.isLoading = true;
    this.parentCategory = undefined;
    try {
      if (parentCategoryId) {
        const resp = await this.root.categoriesApi.fetchCategoryDetails(parentCategoryId);
        this.parentCategory = resp.data.data;
      }
    } finally {
      this.isLoading = false;
    }
  }

  async fetchCategoryDetails(id: string): Promise<void> {
    this.isLoading = true;
    try {
      const resp = await this.root.categoriesApi.fetchCategoryDetails(id);
      this.categoryDetails = resp.data.data;
    } finally {
      this.isLoading = false;
    }
  }

  async editCategory(id: string, details: LocalizedDetails[]): Promise<void> {
    this.isLoading = true;
    this.redirectTocategoriesList = false;

    try {
      const iconFile = this.icon;
      if (iconFile) {
        await this.root.categoriesApi.uploadIconFor(iconFile, id);
      } else {
        const iconUrl = this.categoryDetails?.categoryDetails?.icon;
        if (iconUrl) {
          await this.root.bucketApiService.deleteFile(iconUrl);
        }
      }
      const pictureFile = this.picture;
      if (pictureFile) {
        await this.root.categoriesApi.uploadPictureFor(pictureFile, id);
      } else {
        const pictureUrl = this.categoryDetails?.categoryDetails?.picture;
        if (pictureUrl) {
          const res = await this.root.bucketApiService.deleteFile(pictureUrl);
        }
      }
    } catch (error) {
      console.log(error);
    }

    const category: EditCategory = {
      existingId: id,
      details,
    };

    try {
      await this.root.categoriesApi.edtiCategory(category);
      try {
        await this.root.categoriesStore.fetchCategories();
      } catch (e) {
        console.log(e);
      }
      this.redirectTocategoriesList = true;
    } finally {
      this.isLoading = false;
    }
  }

  parentCategoryName(): CategoryDetails | null {
    const parent = this.parentCategory;
    return (parent && parent.categoryDetails) || null;
  }
}
