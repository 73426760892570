import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {LoadingView, useRootStore} from '@root/components';
import LoginPage from '@root/pages/login/login.page';
import DeleteConfirmation from "@root/pages/fb-events/components/delete-confirmation";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import {FBjob} from "@root/types";
import {Form, Formik, useFormik} from 'formik';
import * as Yup from 'yup';
// import ScheduleField from "@root/pages/fb-events/components/schedule-field";
import CronInput from "@root/pages/fb-events/components/cron-input";

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    },
    container: {
        minHeight: '100%'
    },
    actionCell: {
        padding: 0,
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
    },
    noWrapCell: {
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
        maxWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    errorText: {
        color: 'red'
    }
});

const validationSchema = Yup.object({
    collector_uid: Yup.string().required('Required'),
    source: Yup.string().url('Must be a valid URL').required('Required'),
    schedule: Yup.string()
        .required('Required')
        .test('is-cron-expression', 'Must be a valid cron expression', (value) => {
            return value ? /^(\*\/[0-9]*|\*|[0-9]+) (\*\/[0-9]*|\*|[0-9]+) (\*\/[0-9]*|\*|[0-9]+) (\*\/[0-9]*|\*|[0-9]+) (\*\/[0-9]*|\*|[0-9]+)$/.test(value) : false;
        })
        .test('is-not-more-than-once-a-day', 'Schedule should not be more frequent than once a day.', (value) => {
            const isNotMoreThanOnceADay = value ? !/^(\*\/[1-9][0-9]*|\*\/[0-9]*[1-9]|\* [0-9,]* \* \* \*|\* \* \* \* \*)/.test(value) : false;
            return isNotMoreThanOnceADay;
        }),
    active: Yup.boolean().required('Required'),
});


const FBjobsTablePage = observer(() => {
    const classes = useStyles();
    const {fbJobsStore, fbCollectorsStore} = useRootStore();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [eventToDelete, setEventToDelete] = useState<string | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    type FBjobFormValues = Omit<FBjob, 'uid'> & { uid?: string };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    const handleSubmit = (values: FBjobFormValues, {setSubmitting}: {
        setSubmitting: (isSubmitting: boolean) => void
    }) => {
        console.log(values);

        if (values.uid) {

            fbJobsStore.update(values as FBjob)
                .then(() => {
                    console.log(`Updated job with id: ${values.uid}`);
                    handleCloseDialog();
                })
                .catch((error) => {
                    console.error(`Failed to update job with id: ${values.uid}. Error: ${error}`);
                    setErrorMessage(error.response?.data?.message || 'An error occurred');
                });
        } else {

            fbJobsStore.create(values)
                .then(() => {
                    console.log(`Created new job`);
                    handleCloseDialog();
                })
                .catch((error) => {
                    console.error(`Failed to create new job. Error: ${error}`);
                    setErrorMessage(error.response?.data?.message || 'An error occurred');
                });
        }
        setSubmitting(false);
    };


    const formik = useFormik<FBjobFormValues>({
        initialValues: {
            collector_uid: '',
            source: '',
            schedule: '0 12 * * 5',
            active: true,
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });
    const handleOpenDialog = (job: FBjob | null = null) => {
        fbCollectorsStore.fetchCollectors().then(() => {
            if (job) {
                formik.setValues(job);
            } else {
                formik.resetForm();
                setErrorMessage(null)
                if (fbCollectorsStore.collectors.length > 0) {
                    formik.setFieldValue('collector_uid', fbCollectorsStore.collectors[0].uid);
                }
            }
            setDialogOpen(true);
        });
    };


    useEffect(() => {
        fbJobsStore.fetchJobs();

    }, []);
    const cancelDelete = () => {
        setDeleteDialogOpen(false);
    };

    const handleDelete = (id: string) => {
        setEventToDelete(id);
        setDeleteDialogOpen(true);
    };
    const confirmDelete = () => {
        fbJobsStore.delete(eventToDelete!)
            .then(() => {
                console.log(`Delete event with id: ${eventToDelete}`);
                setDeleteDialogOpen(false);
            })
            .catch((error) => {
                console.error(`Failed to delete event with id: ${eventToDelete}. Error: ${error}`);
            });
    };


    if (fbJobsStore.isLoading) {
        return <LoadingView title="Jobs are loading..."/>;
    }
    if (!localStorage.getItem('authToken')) {
        return <LoginPage/>;
    }

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon/>}
                onClick={() => handleOpenDialog()}
            >
                Add Job
            </Button>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell>UID</TableCell>
                            <TableCell align="right">Collector UID</TableCell>
                            <TableCell align="right">Source</TableCell>
                            <TableCell align="right">Schedule</TableCell>
                            <TableCell align="right">Active</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        (fbJobsStore.jobs.length === 0) ?
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <b>No jobs found.</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody> :

                            <TableBody>
                                {fbJobsStore.jobs.map((job) => (
                                    <TableRow key={job.uid}>
                                        <TableCell className={classes.actionCell}>
                                            <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenDialog(job);
                                            }}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(job.uid);
                                            }}>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {job.uid}
                                        </TableCell>
                                        <TableCell align="right">{job.collector_uid}</TableCell>
                                        <TableCell className={classes.noWrapCell}>
                                            <Link href={job.source} target="_blank" rel="noopener noreferrer">
                                                {job.source}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="right">{job.schedule}</TableCell>
                                        <TableCell align="right">{job.active}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                    }
                </Table>
            </TableContainer>


            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="job-dialog-title"
            >
                <DialogTitle id="job-dialog-title">{formik.values.uid ? 'Edit Job' : 'Add Job'}</DialogTitle>
                <DialogContent>
                    <Formik<FBjobFormValues>
                        onSubmit={(values, formikHelpers) => formik.handleSubmit()}
                        {...formik}
                    >
                        <Form>
                            <FormControl fullWidth margin="dense">
                                <InputLabel id="collector_uid-label">Collector UID</InputLabel>
                                <Select
                                    labelId="collector_uid-label"
                                    id="collector_uid"
                                    name="collector_uid"
                                    value={formik.values.collector_uid}
                                    onChange={formik.handleChange}
                                >
                                    {fbCollectorsStore.collectors.map((collector) => (
                                        <MenuItem key={collector.uid} value={collector.uid}>
                                            {collector.credentials.username}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                margin="dense"
                                id="source"
                                label="Source"
                                type="text"
                                fullWidth
                                value={formik.values.source}
                                onChange={formik.handleChange}
                                error={formik.touched.source && Boolean(formik.errors.source)}
                                helperText={formik.touched.source && formik.errors.source}
                            />


                            <CronInput
                                value={formik.values.schedule}
                                onChange={(newValue) => {
                                    formik.setFieldValue('schedule', newValue);
                                }}
                            />
                            {formik.touched.schedule && formik.errors.schedule ? (
                                <div className={classes.errorText}>
                                    {formik.errors.schedule}
                                    <br/>
                                    More info you can find on
                                    <br/>
                                    <a href="https://crontab.guru" target="_blank"
                                       rel="noopener noreferrer">https://crontab.guru</a>
                                </div>
                            ) : null}
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.active}
                                        onChange={formik.handleChange}
                                        name="active"
                                        color="primary"
                                    />
                                }
                                label={formik.values.active ? "Active" : "Inactive"}
                            />
                            {errorMessage && <div className={classes.errorText}>{errorMessage}</div>}
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary">
                                    {formik.values.uid ? 'Save' : 'Add'}
                                </Button>

                            </DialogActions>
                        </Form>
                    </Formik>
                </DialogContent>
            </Dialog>
            <DeleteConfirmation
                open={deleteDialogOpen}
                onClose={cancelDelete}
                onConfirm={confirmDelete}
            />
        </>
    )
        ;
});

export default FBjobsTablePage;
