import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {LoadingView, useRootStore} from '@root/components';
import {makeStyles} from "@material-ui/core/styles";
import LoginPage from '@root/pages/login/login.page';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import {FBcollector} from "@root/types";
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import FormikField from '@root/pages/fb-events/components/formik-field';
import DeleteConfirmation from "@root/pages/fb-events/components/delete-confirmation";


const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    },
    container: {
        minHeight: '100%'
    },
    actionCell: {
        padding: 0,
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
    },
    noWrapCell: {
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
        maxWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    errorText: {
        color: 'red'
    },
    fullWith: {
        width: '100%',
    }
});

const CollectorSchema = Yup.object().shape({
    username: Yup.string()
        .email('Invalid email')
        .required('Required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Required'),
});

const FBcollectorsTablePage = observer(() => {
    const classes = useStyles();
    const {fbCollectorsStore} = useRootStore();
    const initialCollector = {
        uid: '',
        collector: '',
        logger: '',
        store: '',
        journal: '',
        credentials: {
            username: '',
            password: '',
        },
        params: null,
    };

    useEffect(() => {
        fbCollectorsStore.fetchCollectors();
    }, []);


    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [collectorIdToDelete, setEventToDelete] = useState<string | null>(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [collectorToEdit, setCollectorToEdit] = useState<FBcollector | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const handleDelete = (id: string) => {
        setEventToDelete(id);
        setDeleteDialogOpen(true);
    };

    const confirmDelete = () => {
        fbCollectorsStore.delete(collectorIdToDelete!)
            .then(() => {
                console.log(`Delete collector with id: ${collectorIdToDelete}`);
                setDeleteDialogOpen(false);
            })
            .catch((error) => {
                console.error(`Failed to delete collector with id: ${collectorIdToDelete}. Error: ${error}`);
            });
    };

    const cancelDelete = () => {
        setDeleteDialogOpen(false);
    };

    const handleAdd = () => {
        setErrorMessage(null)
        setCollectorToEdit(initialCollector);
        setEditDialogOpen(true);
    };

    const handleEdit = (collector: FBcollector) => {
        setErrorMessage(null)
        setCollectorToEdit(collector);
        setEditDialogOpen(true);
    };

    const handleSubmit = (values: FBcollector['credentials'], {setSubmitting}: {
        setSubmitting: (isSubmitting: boolean) => void
    }) => {
        if (collectorToEdit) {
            if (collectorToEdit.uid) {
                if (values.username !== collectorToEdit.credentials.username || values.password !== collectorToEdit.credentials.password) {

                    fbCollectorsStore.update({...collectorToEdit, credentials: values})
                        .then(() => {
                            console.log(`Updated collector with id: ${collectorToEdit.uid}`);
                            setEditDialogOpen(false);
                            setCollectorToEdit(initialCollector);
                        })
                        .catch((error) => {
                            console.error(`Failed to update collector with id: ${collectorToEdit.uid}. Error: ${error}`);
                            setErrorMessage(error.response?.data?.message || 'An error occurred');
                        });
                } else {

                    setEditDialogOpen(false);
                }
            } else {

                fbCollectorsStore.create({...collectorToEdit, credentials: values})
                    .then(() => {
                        console.log(`Created new collector with id: ${collectorToEdit.uid}`);
                        setEditDialogOpen(false);
                        setCollectorToEdit(initialCollector);
                    })
                    .catch((error) => {
                        console.error(`Failed to create new collector. Error: ${error}`);
                        setErrorMessage(error.response?.data?.message || 'An error occurred');
                    });
            }
        }
        setSubmitting(false);
    };


    const closeEditDialog = () => {
        setEditDialogOpen(false);
        // setFormSubmitted(false);
        setCollectorToEdit(initialCollector);
    };
    if (fbCollectorsStore.isLoading) {
        return <LoadingView title="Collectors are loading..."/>;
    }
    if (!localStorage.getItem('authToken')) {
        return <LoginPage/>;
    }

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon/>}
                onClick={handleAdd}
            >
                Add Collector
            </Button>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell>UID</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Collector</TableCell>
                            <TableCell>Logger</TableCell>
                            <TableCell>Store</TableCell>
                            <TableCell>Journal</TableCell>
                            <TableCell>Password</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        (fbCollectorsStore.collectors.length === 0) ?
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <b>No collectors found.</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody> :
                            <TableBody>
                                {fbCollectorsStore.collectors.map((collector) => (
                                    <TableRow key={collector.uid}>
                                        <TableCell className={classes.actionCell}>
                                            <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                handleEdit(collector);
                                            }}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(collector.uid);
                                            }}>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>{collector.uid}</TableCell>
                                        <TableCell>{collector.credentials.username}</TableCell>
                                        <TableCell>{collector.collector}</TableCell>
                                        <TableCell>{collector.logger}</TableCell>
                                        <TableCell>{collector.store}</TableCell>
                                        <TableCell>{collector.journal}</TableCell>

                                        <TableCell>{collector.credentials.password}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                    }
                </Table>
            </TableContainer>

            <DeleteConfirmation
                open={deleteDialogOpen}
                onClose={cancelDelete}
                onConfirm={confirmDelete}
            />

            <Dialog
                open={editDialogOpen}
                onClose={closeEditDialog}
                aria-labelledby="edit-dialog-title"
            >
                <DialogTitle id="edit-dialog-title">
                    {collectorToEdit?.uid ? 'Edit Collector' : 'Add Collector'}
                </DialogTitle>
                <DialogContent>
                    {collectorToEdit && (
                        <Formik<FBcollector['credentials']>
                            initialValues={collectorToEdit.credentials}
                            validationSchema={CollectorSchema}
                            onSubmit={handleSubmit}
                        >
                            {({errors, touched}) => (
                                <Form>
                                    <Grid container direction="column" spacing={2}>
                                        <FormikField label="Email" name="username" type="email"/>
                                        <FormikField label="Password" name="password" type="password"/>
                                        {errorMessage && <div className={classes.errorText}>{errorMessage}</div>}
                                    </Grid>
                                    <DialogActions style={{justifyContent: 'space-between'}}>
                                        <Button onClick={closeEditDialog} color="primary">
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            autoFocus
                                        >
                                            Save
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
});

export default FBcollectorsTablePage;